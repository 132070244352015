div
<template>
    <div class="tribute-tab-section">
        <v-tabs-items style="max-width: 100%; overflow: visible" v-model="tabIndex">
            <v-tab-item value="share">
                <div>
                    <ShareTab
                        :key="shareTabRefreshKey"
                        :min-photos="minPhotos"
                        :tribute-video="tributeVideo"
                        :event="event"
                        :service="service"
                        @upload-start="files => $emit('upload-start', files)"
                        @init-share-modal="initShareModal"
                        @current-files="files => handleOverlayFiles(files)"
                    />
                </div>
            </v-tab-item>

            <v-tab-item value="gallery">
                <div>
                    <!-- START: Gallery -->
                    <div class="text-right">
                        <div class="gallery-actions d-flex justify-space-between align-center flex-wrap">
                            <strong
                                ><span>
                                    All Slides ({{ tributeVideo.totalPhotos ? tributeVideo.totalPhotos : '0' }})
                                </span></strong
                            >

                            <div style="gap: 5px" class="d-flex flex-wrap">
                                <custom-tooltip
                                    v-if="$auth.role.includes('SuperAdmin')"
                                    :tooltipProps="{ maxWidth: 300, top: true }"
                                >
                                    <template v-slot:activator>
                                        <v-btn @click="handleDevModeToggle(!devMode)" depressed small>
                                            <span class="mr-2">Dev Mode</span>
                                            <font-awesome-icon
                                                style="font-size: 0.9rem"
                                                icon="fa-regular fa-code"
                                            ></font-awesome-icon
                                        ></v-btn>
                                    </template>
                                    <template v-slot:content>
                                        <span>Dev Tools</span>
                                    </template>
                                </custom-tooltip>

                                <v-btn @click="initDuplicateModal" depressed small>
                                    <span class="">Duplicates</span>
                                </v-btn>

                                <!-- <custom-tooltip :tooltipProps="{ maxWidth: 300, top: true }">
                                    <template v-slot:activator>
                                        <v-btn @click="initShareModal" depressed small>
                                            <span class="mr-2"> Share</span>
                                            <font-awesome-icon
                                                style="font-size: 0.9rem"
                                                icon="fa-regular fa-share"
                                            ></font-awesome-icon
                                        ></v-btn>
                                    </template>
                                    <template v-slot:title>
                                        <span>Family Link</span>
                                    </template>
                                    <template v-slot:content>
                                        <span
                                            >Share a link with the family so they can upload their own photos. This link
                                            will prompt them to download our mobile app, if they open on an iPhone or
                                            iPad.</span
                                        >
                                    </template>
                                </custom-tooltip> -->

                                <custom-tooltip :tooltipProps="{ maxWidth: 300, top: true }">
                                    <template v-slot:activator>
                                        <v-btn @click="initUploadModal" dark color="orange" depressed small>
                                            <span class="mr-2"> Upload</span>
                                            <font-awesome-icon
                                                style="font-size: 0.9rem"
                                                icon="fa-regular fa-cloud-arrow-up"
                                            ></font-awesome-icon
                                        ></v-btn>
                                    </template>
                                    <template v-slot:title>
                                        <span>Upload Media</span>
                                    </template>
                                    <template v-slot:content>
                                        <span
                                            >Add photos and videos to
                                            {{ tributeVideo.firstName ? tributeVideo.firstName + "'s" : '' }} Tribute
                                            Video.</span
                                        >
                                    </template>
                                </custom-tooltip>

                                <v-menu left attach="" offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" small depressed>
                                            <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" />
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <div
                                            class="action-list-item"
                                            v-for="(item, index) in actionItems"
                                            :key="index"
                                            @click="item.callback"
                                        >
                                            <custom-tooltip :tooltipProps="{ maxWidth: 300, top: true }">
                                                <template v-slot:activator>
                                                    <v-list-group v-if="item.label == 'Sort'" @click.stop no-action>
                                                        <template v-slot:activator>
                                                            <v-list-item-content
                                                                class="text-left d-flex align-items-center"
                                                            >
                                                                <v-list-item-title>
                                                                    <font-awesome-icon class="mr-3" :icon="item.icon" />
                                                                    {{ item.label }}
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </template>

                                                        <v-list-item
                                                            class="action-list-item action-list-subgroup-item"
                                                            v-for="(item, index) in orderOptions"
                                                        >
                                                            <v-list-item-title
                                                                @click="reorderPhotos(item.value)"
                                                                class="d-flex align-items-center justify-end"
                                                            >
                                                                {{ item.label }}
                                                                <font-awesome-icon
                                                                    class="ml-3"
                                                                    style="width: 1rem; height: 1rem"
                                                                    :icon="item.icon"
                                                                ></font-awesome-icon>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                    </v-list-group>

                                                    <v-list-item v-else>
                                                        <v-list-item-title class="text-left d-flex align-items-center">
                                                            <font-awesome-icon
                                                                class="mr-3"
                                                                style="width: 1rem; height: 1rem"
                                                                :icon="item.icon"
                                                            ></font-awesome-icon>
                                                            {{ item.label }}
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                                <template v-slot:title>
                                                    <span>{{ item.tooltipTitle }}</span>
                                                </template>
                                                <template v-slot:content>
                                                    <span>{{ item.tooltip }}</span>
                                                </template>
                                            </custom-tooltip>
                                        </div>
                                    </v-list>
                                </v-menu>
                            </div>
                        </div>
                    </div>

                    <!-- START: Life Event Section -->
                    <!-- TODO: uncomment when life events ready  -->
                    <!-- <tribute-life-events :event="event" ref="lifeEvents" class="mb-4"></tribute-life-events> -->
                    <!-- END: Life Event Section -->

                    <div v-if="tabIndex == 'gallery' && !onboarding">
                        <overlay-drag-drop
                            ref="overlayUploader"
                            @files-changed="files => handleOverlayFiles(files)"
                        ></overlay-drag-drop>
                    </div>

                    <TributePhotoGallery
                        v-if="!onboarding"
                        ref="gallery"
                        @toggleDevMode="val => handleDevModeToggle(val)"
                        @refresh-sync="$emit('refresh-sync')"
                        @refresh-tribute="event => $emit('refresh-tribute', event)"
                        :devMode="devMode"
                        :event="event"
                        :service="service"
                    />

                    <!-- END: Gallery -->
                </div>
                <div class="text-right pt-2">
                    <v-btn @click="$emit('changeTab', 'templates')" depressed>
                        <span>Theme</span>
                        <font-awesome-icon class="ml-2" icon="fa-regular fa-chevron-right"></font-awesome-icon>
                    </v-btn>
                </div>
            </v-tab-item>

            <v-tab-item value="templates">
                <div class="py-4">
                    <SelectedThemeSummary />
                    <tribute-templates
                        v-if="token"
                        ref="templates"
                        @refresh-sync="$emit('refresh-sync')"
                        @template-customize-main-photo="$emit('template-customize-main-photo')"
                        :playlistRedirect="playlistRedirect"
                        :parentTabIndex="tabIndex"
                        :event="event"
                        :service="service"
                        :token="token"
                        :activeTab="tabIndex == 'templates'"
                    />
                    <div class="d-flex justify-space-between px-2 pt-6">
                        <v-btn @click="$emit('changeTab', 'gallery')" depressed>
                            <font-awesome-icon class="mr-2" icon="fa-regular fa-chevron-left"></font-awesome-icon>
                            <span>Slides</span>
                        </v-btn>
                        <v-btn @click="$emit('changeTab', 'music')" depressed>
                            <span>Music</span>
                            <font-awesome-icon class="ml-2" icon="fa-regular fa-chevron-right"></font-awesome-icon>
                        </v-btn>
                    </div>
                </div>
            </v-tab-item>

            <v-tab-item value="music">
                <music-by-genre
                    v-if="token"
                    @refresh-sync="$emit('refresh-sync')"
                    :parentTabIndex="tabIndex"
                    :event="event"
                    :token="token"
                    :showCopyrightPlaylist="!!copyrightSafeRender.id"
                ></music-by-genre>
                <div class="d-flex justify-space-between">
                    <v-btn @click="$emit('changeTab', 'templates')" depressed>
                        <font-awesome-icon class="mr-2" icon="fa-regular fa-chevron-left"></font-awesome-icon>
                        <span>Theme</span>
                    </v-btn>
                    <v-btn @click="$emit('changeTab', 'summary')" depressed>
                        <span>Summary</span>
                        <font-awesome-icon class="ml-2" icon="fa-regular fa-chevron-right"></font-awesome-icon>
                    </v-btn>
                </div>
            </v-tab-item>

            <v-tab-item eager value="summary">
                <div>
                    <tribute-video-summary
                        ref="summary"
                        :devMode="devMode"
                        :service="service"
                        :render="standardRender"
                        :event="event"
                        :key="refreshKey"
                        @changeTab="val => changeTab(val)"
                        @render-started="$emit('render-started')"
                        @refresh-sync="$emit('refresh-sync')"
                        :minPhotos="minPhotos"
                        :activeTab="tabIndex == 'summary'"
                    />
                    <div class="text-left">
                        <v-btn @click="$emit('changeTab', 'music')" depressed>
                            <font-awesome-icon class="mr-2" icon="fa-regular fa-chevron-left"></font-awesome-icon>
                            <span>Music</span>
                        </v-btn>
                    </div>
                </div>
            </v-tab-item>

            <v-tab-item value="video">
                <v-window v-model="renderWindowIndex">
                    <v-window-item :key="0">
                        <div v-if="copyrightSafeRender.id" @click="renderWindowIndex = 1" class="d-flex justify-end">
                            <v-btn depressed>
                                <span> Public</span>
                                <font-awesome-icon class="ml-2" icon="fa-regular fa-arrow-right"></font-awesome-icon>
                            </v-btn>
                        </div>

                        <final-video
                            v-if="standardRender.id"
                            ref="standardTributeRender"
                            @render-deleted="$emit('render-deleted')"
                            @submit-render="handleRenderSubmit"
                            :event="event"
                            :service="service"
                            :render="standardRender"
                            :show-render-type-label="copyrightSafeRender.id > 0"
                            :originalTributePlayableLink="originalTributePlayableLink"
                        />

                        <div
                            class="text-center text-overline d-flex align-center justify-center"
                            v-else
                            style="height: 200px"
                        >
                            <p>No render available</p>
                        </div>
                    </v-window-item>
                    <v-window-item :key="1">
                        <div @click="renderWindowIndex = 0">
                            <v-btn depressed>
                                <font-awesome-icon class="mr-2" icon="fa-regular fa-arrow-left"></font-awesome-icon>
                                <span>Original</span>
                            </v-btn>
                        </div>

                        <final-video
                            v-if="copyrightSafeRender.id"
                            ref="copyrightSafeTributeRender"
                            @render-deleted="$emit('render-deleted')"
                            @submit-render="handleRenderSubmit"
                            :event="event"
                            :service="service"
                            :render="copyrightSafeRender"
                            :originalTributePlayableLink="originalTributePlayableLink"
                        />

                        <div
                            class="text-center text-overline d-flex align-center justify-center"
                            v-else
                            style="height: 200px"
                        >
                            <p>No render available</p>
                        </div>
                    </v-window-item>
                </v-window>

                <div v-if="displayHideBtn" class="text-center">
                    <v-btn
                        v-if="event.hidden"
                        small
                        class="p-3 mx-2"
                        color="warning"
                        @click="$emit('unhide-event', event)"
                        >Un Hide</v-btn
                    >
                    <v-btn v-else small class="p-3 mx-2" color="warning" @click="$emit('hide-event', event)"
                        >Hide</v-btn
                    >
                </div>
            </v-tab-item>
        </v-tabs-items>

        <tribute-upload-modal
            ref="uploadModal"
            @refresh-photos="handleUploadModalRefresh"
            @all-uploads-complete="handleAllUploadsComplete"
            @modal-visible="val => (uploadModal = val)"
            :eventId="event.id"
        ></tribute-upload-modal>

        <share-upload-modal
            v-if="tributeVideo.deeplink || tributeVideo.familyLink"
            ref="shareModal"
            :tributeVideo="tributeVideo"
            :service="service"
            :original-tribute-playable-link="originalTributePlayableLink"
            @submit-invites="data => handleInviteSubmit(data)"
            @invalidEmail="email => handleInvalidEmail(email)"
            @refresh-link="val => handleRefreshLink(val)"
        ></share-upload-modal>

        <!-- tribute qr code pdf printout  -->
        <qr-printout
            v-if="tributeVideo.familyLink && tributeVideo.deeplink"
            title="Tribute Video"
            :service="service"
            :link="linkType == 'family' ? tributeVideo.familyLink : tributeVideo.deeplink"
            ref="qrPrint"
        />
        <!-- tribute qr code pdf printout  -->

        <!-- START: Download All Modal-->
        <branded-modal @close="downloadAllModal = false" max-width="400px" v-model="downloadAllModal">
            <template v-slot:title>
                <div>Download Collection</div>
            </template>

            <template v-slot:body>
                <v-card-text> This will download all {{ tributeVideo.totalPhotos + 1 }} photos. </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn depressed @click="downloadAllModal = false">Cancel</v-btn>
                    <v-btn depressed @click="initCollectionDownload" dark color="#0d3d60">Download</v-btn>
                </v-card-actions>
            </template>
        </branded-modal>
        <!-- END: Download All Modal-->

        <!-- START: Download Progress Modal-->
        <branded-modal
            @close="showCollectionDownloadProgress = false"
            max-width="450px"
            v-model="showCollectionDownloadProgress"
        >
            <template v-slot:title>
                <div>Download Progress</div>
            </template>

            <template v-slot:body>
                <v-card-text>
                    <v-progress-linear
                        height="25"
                        dark
                        v-model="overallProgress"
                        :color="overallProgress == 100 ? 'success' : 'primary'"
                        >{{ overallProgress }}%</v-progress-linear
                    >
                </v-card-text>

                <v-card-actions>
                    <v-btn depressed color="error" @click="cancelDownload">Cancel</v-btn>
                </v-card-actions>
            </template>
        </branded-modal>
        <!-- END: Download Progress Modal-->

        <!-- START: Download Progress Modal-->
        <branded-modal @close="maxItemsModal = false" max-width="450px" v-model="maxItemsModal">
            <template v-slot:title>
                <div>Max Slides</div>
            </template>

            <template v-slot:body>
                <v-form ref="maxItemForm" v-model="formValid">
                    <v-card-text>
                        <!-- <v-text-field
                            label="Max Slides"
                            @keydown.enter="updateMaxItems(event.id, maxItems)"
                            v-model="maxItems"
                            type="number"
                            min="1"
                            :max="maxLimit"
                            :rules="[notGreaterThanMaxLimit]"
                        ></v-text-field> -->

                        <span class="text-caption">Max Tribute Video Items</span>
                        <div style="gap: 12px" class="d-flex align-center">
                            <v-slider
                                v-model="maxItems"
                                min="1"
                                max="500"
                                :rules="[
                                    v => v >= 10 || 'Cannot be less than 10',
                                    v => v <= 500 || 'Cannot be greater than 500',
                                ]"
                            >
                            </v-slider>
                            <v-text-field
                                v-model="maxItems"
                                type="number"
                                style="max-width: 80px"
                                dense
                                outlined
                                :rules="[
                                    v => v >= 10 || 'Cannot be less than 10',
                                    v => v <= 500 || 'Cannot be greater than 500',
                                ]"
                            ></v-text-field>
                        </div>
                        <v-slide-y-transition>
                            <div class="text-caption" v-show="maxItems > 300">
                                Warning: Exceeding 300 tribute video items may cause exceptionally long render times.
                            </div>
                        </v-slide-y-transition>
                    </v-card-text>

                    <v-card-actions class="d-flex justify-space-between">
                        <v-btn depressed @click="cancelMaxItemsModal">Cancel</v-btn>
                        <v-btn
                            depressed
                            :dark="!(loading || !formValid)"
                            :disabled="loading || !formValid"
                            color="#0d3d60"
                            @click="updateMaxItems(event.id, maxItems)"
                            >Save</v-btn
                        >
                    </v-card-actions>
                </v-form>
            </template>
        </branded-modal>
        <!-- END: Download Progress Modal-->

        <DuplicatePhotosModal v-model="duplicateModal" v-if="service.id" :serviceId="service.id" :devMode="devMode" />
    </div>
</template>
<script>
import TributePhotos from '@/components/ManageService/Tribute/TributePhotos.vue';
import TributeTemplates from '@/components/ManageService/Tribute/TributeTemplates.vue';
import TributeVideoSummary from '@/components/ManageService/Tribute/TributeVideoSummary.vue';
import TributePhotoGallery from '@/components/ManageService/Tribute/TributePhotoGallery.vue';
import TributeQR from '@/components/ManageService/Tribute/TributeQR.vue';
import TributeMusicTable from '@/components/Tables/Admin/TributeMusicTable.vue';
import TributePlaylist from '@/components/ManageService/Tribute/TributePlaylist.vue';
import TributePlaylistExpansion from '@/components/ManageService/Tribute/TributePlaylistExpansion.vue';
import QrPrintout from '@/components/ManageService/Tribute/QrPrintout.vue';
import EmailInvite from '@/components/ui/EmailInvite.vue';
import TributeLifeEvents from '@/components/ManageService/Tribute/TributeLifeEvents.vue';
import TributeStories from '@/components/ManageService/Tribute/TributeStories.vue';
import TributeUploadModal from '@/components/ManageService/Tribute/TributeUploadModal.vue';
import ShareUploadModal from '@/components/ManageService/Tribute/ShareUploadModal.vue';
import FinalVideo from '@/components/ManageService/Tribute/FinalVideo.vue';
import { mapActions } from 'vuex';
import BrandedModal from '@/components/ui/BrandedModal.vue';
import MusicByGenre from '@/components/ManageService/Tribute/MusicByGenre.vue';
import OverlayDragDrop from '@/components/ui/OverlayDragDrop.vue';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import JSZip from 'jszip';
import TributePhotoService from '@/services/tributePhoto.service';
// import ApiService from '@/services/api.service';
import ImageDedupeItem from '@/components/ManageService/Tribute/ImageDedupeItem.vue';
import ShareTab from '@/components/ManageService/Tribute/Tabs/ShareTab.vue';
import SelectedThemeSummary from '@/components/ManageService/Tribute/SelectedThemeSummary.vue';
import DuplicatePhotosModal from '@/components/ManageService/Tribute/DuplicatePhotosModal.vue';

export default {
    name: 'TributeTabSection',
    data() {
        return {
            photoGroupViewModal: false,
            selectedPhotoGroup: null,
            selectedGroupType: null,
            duplicateModal: false,
            renderWindowIndex: 0,
            dialog: false,
            playlistRedirect: false,
            uploadModal: false,
            search: '',
            musicSearch: '',
            loading: false,
            musicWindow: 0,
            galleryView: 1,
            uploadProgress: 0,
            selectedOrderOption: 0,
            downloadAllModal: false,
            collectionTotalBytes: 0,
            downloadProgress: {},
            overallProgress: 0,
            showCollectionDownloadProgress: false,
            downloadCancelToken: null,
            galleryRefreshKey: 0,
            maxItemsModal: false,
            maxItems: 0,
            maxLimit: 500,
            formValid: false,
            token: '',
            linkType: 'family',
            orderOptions: [
                { label: 'Original', icon: 'fa-regular fa-arrow-right', value: 0 },
                { label: 'Shuffle', icon: 'fa-regular fa-shuffle', value: 1 },
            ],
            actionItems: [
                {
                    label: 'Download',
                    icon: 'fa-regular fa-arrow-down-to-bracket',
                    callback: this.openDownloadModal,
                    tooltip: '',
                    tooltipTitle: 'Download All Photos',
                },
                // {
                //     label: 'Share',
                //     icon: 'fa-regular fa-share-from-square',
                //     callback: this.initShareModal,
                //     tooltip: 'Share uploader link',
                // },
                {
                    label: 'Add Slide',
                    icon: 'fa-regular fa-plus',
                    callback: this.initBlankImage,
                    tooltip: 'Add a title slide. Useful for chaptering video, or inserting quotes.',
                    tooltipTitle: 'Add Title Slide',
                },
                // {
                //     label: 'Upload Media',
                //     icon: 'fa-regular fa-cloud-arrow-up',
                //     callback: this.initUploadModal,
                //     tooltip: 'Upload media',
                // },
                {
                    label: 'Max Slides',
                    icon: 'fa-regular fa-gauge-max',
                    callback: this.initMaxItemsModal,
                    tooltip: 'Adjust number maximum tribute video slides.',
                    tooltipTitle: 'Max Slide Limit',
                },
                {
                    label: 'Sort',
                    icon: 'fa-regular fa-arrow-down-short-wide',
                    callback: '',
                    tooltip: 'Sorts all photos and videos associated with this tribute video.',
                    tooltipTitle: 'Sort Collection',
                },
            ],
            devModeActionItem: {
                label: 'Dev Mode',
                icon: 'fa-regular fa-code',
                callback: () => this.handleDevModeToggle(!this.devMode),
                tooltip: 'Toggle dev mode view',
            },
        };
    },
    watch: {
        musicSearch() {
            this.$refs.search = this.musicSearch;
        },
        tabIndex(newVal) {
            if (this.$refs.playlist) {
                this.$refs.playlist.$refs.musicTable.expanded = [];
            }

            if (this.tabIndex != 'video') {
                this.pauseVideoPlayer('standard');
                this.pauseVideoPlayer('copyright');
            }
        },
        renderWindowIndex(newVal) {
            if (newVal == 0) {
                this.pauseVideoPlayer('copyright');
            } else {
                this.pauseVideoPlayer('standard');
            }
        },
        shareTabRefreshKey(newVal) {
            console.log('share tab refresh key', newVal);
        },
    },
    props: {
        tabIndex: {
            type: String,
            required: true,
        },
        event: {
            type: Object,
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
        minPhotos: {
            type: Number,
            required: true,
        },
        devMode: {
            type: Boolean,
        },
        standardRender: {
            type: Object,
            default: {},
        },
        copyrightSafeRender: {
            type: Object,
            default: {},
        },
        onboarding: {
            type: Boolean,
            default: false,
        },
        refreshKey: {
            type: Number,
            default: 0,
        },
        shareTabRefreshKey: {
            type: Number,
            default: 0,
        },
    },
    components: {
        TributePhotos,
        TributePhotoGallery,
        TributeTemplates,
        TributeVideoSummary,
        TributeMusicTable,
        TributeQR,
        TributePlaylist,
        TributePlaylistExpansion,
        QrPrintout,
        EmailInvite,
        TributeLifeEvents,
        TributeStories,
        TributeUploadModal,
        ShareUploadModal,
        FinalVideo,
        BrandedModal,
        MusicByGenre,
        OverlayDragDrop,
        CustomTooltip,
        ImageDedupeItem,
        ShareTab,
        SelectedThemeSummary,
        DuplicatePhotosModal,
    },
    computed: {
        selectedPhotos() {
            return this.$store.state.tributeVideo.selectedPhotos;
        },
        selectedTemplates() {
            return this.$store.state.tributeVideo.selectedTemplates;
        },
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
        originalTributePlayableLink() {
            if (!this.copyrightSafeRender.id || !this.standardRender.id) return null;

            return this.standardRender.hlsDirectPlayUrl;
        },
        displayHideBtn() {
            if (this.standardRender && this.standardRender.status == 2) return true;

            return false;
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        viewGroup(group, groupType) {
            this.photoGroupViewModal = true;
            this.selectedPhotoGroup = group;
            this.selectedGroupType = groupType;
        },
        handleAllUploadsComplete() {
            if (this.tabIndex === 'share') {
                this.$emit('tab-index', 'gallery');
            }
        },
        handleOnboardUploadSuccess() {
            const gallery = this.$refs.gallery;
            if (!gallery) return;

            gallery.onboardUploadHandler();
        },
        notGreaterThanMaxLimit(value) {
            return value <= this.maxLimit || `Value must not be greater than ${this.maxLimit}`;
        },
        checkRemainingAllowedUploads(eventId) {
            return this.axiosInstance
                .get(`/TributeVideoPhoto/remaining-allowed-uploads/${eventId}`)
                .then(response => {
                    // log('==== getUploadUrl() called ====', 'silver');
                    // 'response.data', response.data;
                    return response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        updateMaxItems(eventId, max) {
            if (!this.formValid) return;

            this.loading = true;

            this.axiosInstance
                .post(`/TributeVideoPhoto/update-max-items/${eventId}/${max}`)
                .then(response => {
                    this.tributeVideo.maxItems = response.data.maxItems;
                    this.showSnackbar({ message: 'Max slides updated' });
                    this.maxItemsModal = false;
                })
                .catch(error => {
                    console.log(error);
                    this.showSnackbar({ message: 'Error updating max slides', color: 'error' });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        cancelMaxItemsModal() {
            this.maxItemsModal = false;
        },
        initMaxItemsModal() {
            this.maxItemsModal = true;
        },
        refreshSummary() {
            const summary = this.$refs.summary;
            if (!summary) return;

            summary.refreshTributeVideoData();
        },
        handleUploadModalRefresh() {
            const gallery = this.$refs.gallery;
            if (gallery) {
                gallery.handleUploadSuccess();
            }

            this.$emit('refresh-tribute', this.event);
        },
        handleRefreshLink(linkType) {
            let val = null;
            if (linkType == 'family') {
                val = 1;
            } else if (linkType == 'contributor') {
                val = 0;
            } else {
                this.showSnackbar({ message: 'Invalid link type', color: 'error' });
                return;
            }

            this.axiosInstance
                .post(`/TributeVideo/refresh-branch-link/${this.tributeVideo.id}/${val}`)
                .then(resp => {
                    if (val == 0) {
                        this.tributeVideo.deeplink = resp.data.deeplink;
                    }
                    if (val == 1) {
                        this.tributeVideo.familyLink = resp.data.familyLink;
                    }
                    this.$refs.shareModal.refresh();
                })
                .catch(err => {
                    console.log('link refresh errror', err);
                });
        },
        openDownloadModal() {
            this.downloadAllModal = true;
        },
        pauseVideoPlayer(renderType) {
            switch (renderType) {
                case 'standard':
                    if (this.$refs.standardTributeRender) {
                        this.$refs.standardTributeRender.pauseVideoPlayer();
                    }
                    break;
                case 'copyright':
                    if (this.$refs.copyrightSafeTributeRender) {
                        this.$refs.copyrightSafeTributeRender.pauseVideoPlayer();
                    }
                    break;
                default:
                    console.log('Unhandled render Type @ pauseVideoPlayer');
            }
        },
        handleRenderSubmit() {
            const summary = this.$refs.summary;
            if (summary) {
                summary.submitTributeRender();
            }
        },
        handleOverlayFiles(files) {
            const uploadModal = this.$refs.uploadModal;

            if (!uploadModal) return;

            uploadModal.setUploaderFiles(files);
        },
        async initCollectionDownload() {
            const photos = await this.getCollection(this.tributeVideo.id);
            await this.downloadImages(photos);
            this.downloadProgress = {};
            this.overallProgress = 0;
        },
        async downloadImages(photos) {
            try {
                this.loading = true;
                this.downloadAllModal = false;
                this.showCollectionDownloadProgress = true;

                const promises = photos.map(async image => {
                    const blob = await this.getImageData(image);

                    return { ...image, blob: blob };
                });

                const res = await Promise.all(promises);

                //Download multiple as zip folder
                if (photos.length > 1) {
                    const zip = new JSZip();

                    res.forEach((image, index) => {
                        zip.file(image.name, image.blob);
                    });

                    const zipFile = await zip.generateAsync({ type: 'blob' });

                    this.downloadBlob(zipFile, `${this.service.firstName} ${this.service.lastName} Tribute Photos`);
                    this.showSnackbar({ message: 'Photos downloaded' });
                } else {
                    this.downloadBlob(res[0].blob, res[0].name);
                    this.showSnackbar({ message: 'Photo downloaded' });
                }

                // this.loading = false;
            } catch (error) {
                console.log(error, 'zip error');
                this.showSnackbar({ message: 'Error downloading images', color: 'error' });
            }

            this.showCollectionDownloadProgress = false;
        },
        downloadBlob(file, name) {
            const blob = window.URL.createObjectURL(file);
            const anchor = document.createElement('a');

            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = name;
            document.body.appendChild(anchor);
            anchor.click();
            anchor.remove();
            window.URL.revokeObjectURL(blob);
        },
        getImageData(photo, onProgressUpdate) {
            return new Promise((resolve, reject) => {
                this.loading = true;
                this.downloadProgress[photo.id] = 0;

                this.axios({
                    method: 'get',
                    url: photo.url,
                    responseType: 'blob',
                    onDownloadProgress: evt => {
                        const { loaded, total } = evt;
                        this.downloadProgress[photo.id] = Math.round((loaded / total) * 100);
                        this.calculateOverallProgress();
                    },
                })
                    // .then(resp => resp.blob())
                    .then(resp => {
                        resolve(resp.data);
                    })
                    .catch(error => {
                        console.log(error, 'error');
                        this.showSnackbar({ message: 'Error downloading image', color: 'error' });
                        this.loading = false;
                        reject();
                    });
            });
        },
        calculateOverallProgress() {
            const allProgress = Object.values(this.downloadProgress);
            const totalProgress = allProgress.reduce((acc, progress) => acc + progress, 0);
            this.overallProgress = Math.round(totalProgress / Object.keys(this.downloadProgress).length);
        },
        getCollection(id) {
            return this.axiosInstance
                .get(`/TributeVideoPhoto/get-collection-urls/${id}`)
                .then(resp => {
                    return resp.data;
                })
                .catch(err => {
                    this.showSnackbar({ message: 'Error getting total', color: 'error' });
                });
        },
        getCollectionTotalBytes(id) {
            return this.axiosInstance
                .get(`/TributeVideoPhoto/get-collection-total-bytes/${id}`)
                .then(resp => {
                    return resp.data;
                })
                .catch(err => {
                    this.showSnackbar({ message: 'Error getting total', color: 'error' });
                });
        },
        cancelDownload() {
            if (this.downloadCancelToken) {
                this.downloadCancelToken.cancel('Cancelled by user');
            }
        },
        async downloadCollection(id, totalBytes) {
            try {
                this.downloadCancelToken = this.axios.CancelToken.source();

                const resp = await this.axiosInstance.get(`/TributeVideoPhoto/download-tribute-collection/${id}`, {
                    responseType: 'blob',
                    cancelToken: this.downloadCancelToken.token,
                    onDownloadProgress: progressEvent => {
                        this.downloadProgress = Math.round((progressEvent.loaded / totalBytes) * 100);
                    },
                });

                const url = window.URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement('a');
                link.href = url;

                var zipName = `Tribute Photos.zip`;
                if (this.service.firstName && this.service.lastName) {
                    var zipName = `${this.service.firstName} ${this.service.lastName} Tribute Photos.zip`;
                }

                link.setAttribute('download', zipName);
                document.body.appendChild(link);
                link.click();
                this.showSnackbar({ message: 'Collection downloaded' });
            } catch (err) {
                if (this.axios.isCancel(err)) {
                    console.log('Request canceled', err.message);
                } else {
                    this.showSnackbar({ message: 'Error downloading collection', color: 'error' });
                }
            }
            this.downloadCancelToken = null;
        },
        initBlankImage() {
            this.$refs.gallery.initBlankImage();
        },
        selectOption(option) {
            this.selectedOrderOption = option.value;
        },
        initDuplicateModal() {
            this.duplicateModal = true;
        },
        async getCollectionDuplicates(serviceId) {
            if (!serviceId) return;
            try {
                var resp = await this.tributePhotoApiInstance.getCollectionDuplicates(serviceId);
                if (resp.data.results) {
                    this.duplicatePhotos = resp.data.results;
                }
            } catch (error) {
                console.log(error, 'duplicates error');
            }
        },
        async getCollectionSimilars(serviceId) {
            if (!serviceId) return;

            try {
                var resp = await this.tributePhotoApiInstance.getCollectionSimilars(serviceId);
                if (resp.data.results) {
                    this.similarPhotos = resp.data.results;
                }
            } catch (error) {
                console.log(error, 'similars error');
            }
        },
        reorderPhotos(val) {
            if (val == 2) {
                this.showSnackbar({ message: 'Feature coming soon' });
                return;
            }

            if (val > 2) {
                this.showSnackbar({ message: 'Invalid order type', color: 'error' });
                return;
            }

            this.axiosInstance
                .post(`/TributeVideoPhoto/reorder-photos/${this.event.id}?orderType=${val}`)
                .then(resp => {
                    this.showSnackbar({ message: 'Photos shuffled' });
                })
                .catch(err => {
                    this.showSnackbar({ message: 'Error shuffling photos', color: 'error' });
                });
        },

        initNewLifeEvent() {
            this.$refs.lifeEvents.eventModal = true;
        },
        handleUploadProgress(progress) {
            this.$emit('upload-progress', progress);
        },
        handleInvalidEmail(email) {
            this.showSnackbar({ message: `${email} is not a valid email`, color: 'error' });
        },
        handleInviteSubmit(contacts) {
            let data = {
                emailList: contacts.emailList.join(','),
                phoneList: contacts.phoneList.join(','),
                url: contacts.url,
            };

            if (this.$refs.shareModal) {
                this.$refs.shareModal.reset();
            }

            this.axiosInstance
                .post(`/TributeVideoPhoto/tribute-invite/${this.tributeVideo.id}`, data)
                .then(resp => {
                    this.showSnackbar({ message: 'Invitations sent' });
                })
                .catch(err => {
                    this.showSnackbar({ message: 'Error sending invitations', color: 'error' });
                })
                .finally(() => {
                    this.uploadModal = false;
                });
        },
        downloadPdf() {
            // TODO: this is a temp fix for pdf preview position attached to bound service component container
            // can remove custom scroll / vue2html preview position css if inline-size container no longer used for manage
            // service responsiveness
            var main = document.querySelector('#scroll');
            main.scrollTop = 0;
            this.$refs.qrPrint.$refs.html2Pdf.generatePdf();
            this.uploadModal = false;
            // this.$refs.html2Pdf.generatePdf();
        },
        handleDevModeToggle(val) {
            this.$emit('devModeToggle', val);
            // this.devMode = val;
        },
        reorderSet() {
            this.showSnackbar({ message: 'This feature is not available yet' });
        },
        initUploadModal() {
            const uploadModal = this.$refs.uploadModal;

            if (!uploadModal) return;

            uploadModal.displayModal = true;
        },
        initShareModal() {
            const shareModal = this.$refs.shareModal;

            if (!shareModal) return;

            shareModal.displayModal = true;
        },
        disableTab(val) {
            if ((val == 1 || val == 2 || val == 3) && this.tributeVideo.photos.length < this.minPhotos) {
                return true;
            }
            return false;
        },
        changeTab(val) {
            let tab = 'gallery';
            if (val == 'photos') {
                tab = 'gallery';
            } else if (val == 'intro' || val == 'templates') {
                tab = 'templates';
            } else if (val == 'playlist') {
                tab = 'music';
            }

            this.$emit('changeTab', tab);
        },
        updateMusicWindow(val) {
            const component = this.$refs.playlist;
            if (component) {
                this.$refs.playlist.musicWindow = val;
            } else {
                setTimeout(() => {
                    this.$refs.playlist.musicWindow = val;
                }, 200);
            }
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();

        this.tributePhotoApiInstance = TributePhotoService(this.token);

        if (this.tributeVideo.eventId) {
            let result = await this.checkRemainingAllowedUploads(this.tributeVideo.eventId);
            if (result) {
                this.maxItems = result.max;
            }
        }

        this.actionItems[0].tooltip = `Downloads a zip file of all photos, videos, and any media associated with ${
            this.tributeVideo.firstName ? this.tributeVideo.firstName + "'s" : ''
        } tribute video.`;

        // if (this.$auth.role.includes('SuperAdmin')) {
        //     this.actionItems = [...this.actionItems, this.devModeActionItem];
        // }
    },
};
</script>
<style lang="scss">
.modal-uploader {
    height: 200px;
    .tribute-uploader-container {
        #drop-zone {
            height: 200px !important;
        }
    }
}
.uploader-wrap {
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 4px;
}
.custom-green {
    color: #00c853 !important;
}

.tribute-tab-section {
    .action-list-item {
        cursor: pointer;
    }

    .action-list-item:hover {
        background-color: #f8f8f8;
    }

    .action-list-subgroup-item:hover {
        background-color: #ebebeb;
    }
    .gallery-actions {
        padding: 10px 0;
        border-top: 1px solid #e9e9e9;
        border-bottom: 1px solid #e9e9e9;
        margin-bottom: 10px;
    }

    .tribute-action-group {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5rem;
    }

    .event-modal-calendar {
        // height: 100px;
        overflow: hidden;
        transition: height 0.3s ease;
    }

    .order-menu-btn {
        border-radius: 0 5px 5px 0;
        border-left: 1px solid white;
    }
    .container {
        background-color: #f8f8f8;

        .tab-container {
            display: flex;
            margin-bottom: 1rem;
            justify-content: space-between;

            .menu-entry {
                list-style: none;
                padding: 0.5rem 1rem;
                border-bottom: 4px solid rgba(0, 0, 0, 0);
                cursor: pointer;
                transition: 0.3s;
            }

            .menu-entry:hover {
                background-color: rgba(78, 184, 226, 0.097);
                border-radius: 10px 10px 0 0;
                transition: 0.3s;
            }

            .menu-entry.active {
                border-bottom: 4px solid rgb(78, 184, 226);
                transition: 0.3s;
            }
        }
    }
}
</style>
